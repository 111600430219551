import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from 'react';
import { useRouter } from 'next/router';
import {
  AddMessageListenerFn,
  Message,
  MessageListeners,
  NavigationMessageType,
  PostMessageContextType,
  RemoveMessageListenerFn,
} from './types';
import { useShellHandshake } from './hooks';

const PostMessageContext = createContext<PostMessageContextType | null>(null);

export const publish = (payLoad: Message, targetOrigin?: string) => {
  window.parent.postMessage(
    payLoad,
    targetOrigin ? targetOrigin : (process.env.NEXT_PUBLIC_SHELL_URL as string),
  );
};

export const PostMessageContextProvider: FC<{ children?: ReactNode }> = ({
  children,
}) => {
  const [loading, handshake] = useShellHandshake();

  const listeners = useRef<MessageListeners>({
    auth: new Map(),
    dataRefresh: new Map(),
    navigation: new Map(),
    viewMode: new Map(),
    handshake: new Map(),
  });

  const addMessageListener: AddMessageListenerFn = useCallback(
    (id, type, handler) => {
      listeners.current[type].set(id, handler);
    },
    [listeners],
  );

  const removeMessageListener: RemoveMessageListenerFn = useCallback(
    (id, type) => {
      listeners.current[type].delete(id);
    },
    [listeners],
  );

  const notifyListeners = useCallback(
    (message: Message) => {
      const promises = Array.from(listeners.current[message.type]).map(
        ([, handler]) => handler(message),
      );

      Promise.all(promises).catch((e) => {
        console.warn(e);
      });
    },
    [listeners],
  );

  const contextValue: PostMessageContextType = {
    publish,
    addMessageListener,
    removeMessageListener,
  };

  const router = useRouter();

  const navigationHandler = useCallback(
    (message: Message<NavigationMessageType>) => {
      const route = message.payload.route;

      // TODO: this needs to be refactored, so that the navigation logic happens on the shell.
      // This handler would be reduced to this: router.push(messsage.payload.route),
      // while the sidebar links would behave like this: <SidebarItem href="/my-fleet" app="uam" appUrl="/" />

      switch (route) {
        case '/my-fleet':
          router.push('/');
          break;
        case '/charts':
          router.push('/dashboard');
          break;
        case '/settings':
          router.push('/settings/synchronize');
          break;
        case '/reports':
          router.push('/data-reports/');
          break;
        default:
          console.log('Unknown route', route);
          return;
      }
    },
    [router],
  );

  useEffect(() => {
    const messageHandler = (message: Message) => {
      switch (message.type) {
        case 'auth':
          break;
        case 'dataRefresh':
          break;
        case 'viewMode':
          break;
        case 'navigation':
          navigationHandler(message as Message<NavigationMessageType>);
          break;
        default:
          return;
      }

      notifyListeners(message);
    };

    const handleMessage = (event: MessageEvent) => {
      if (event.data?.type) {
        messageHandler(event.data);
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [router, navigationHandler, notifyListeners]);

  if (loading) {
    return null;
  }

  return (
    <PostMessageContext.Provider value={contextValue}>
      {children}
    </PostMessageContext.Provider>
  );
};

export const usePostMessageContext = () => {
  const ctx = useContext(PostMessageContext);

  if (!ctx) {
    throw new Error('Could not find PostMessageContext');
  }

  return ctx;
};
