import { FC, ReactNode } from 'react';
import { Grommet } from 'grommet';
import { theme } from '@/lib/theme';
import { Auth0Provider } from '@auth0/auth0-react';
import config from '@/lib/config';
import { Auth } from '@/lib/components/app/Auth';
import { Apollo } from '@/lib/components/app/Apollo';
import { Typography } from './Typography';
import { BreadcrumbsContextProvider } from './Breadcrumbs';

type AppWrapperProps = {
  children: ReactNode;
};

export const AppWrapper: FC<AppWrapperProps> = ({ children }) => {
  return (
    <Auth0Provider
      {...config.auth0}
      cookieDomain={`.${config.general.domains.root}`}
    >
      <BreadcrumbsContextProvider>
        <Typography />
        <Grommet plain theme={theme} full>
          <Auth>
            <Apollo>{children}</Apollo>
          </Auth>
        </Grommet>
      </BreadcrumbsContextProvider>
    </Auth0Provider>
  );
};
